import _defineProperty from "/builds/aoiChV12/1/ms/ecom/deep/presentation-react/apps/next-commerce/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _slicedToArray from "/builds/aoiChV12/1/ms/ecom/deep/presentation-react/apps/next-commerce/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import { isServer } from '@utils/isServer';
import { parseRegionalUrl } from '@utils/regional';
import { sendEvent } from '@utils/analytics/coreAnalytics';
/*
  Input type for all parameters a facet/filter object could take.
  This includes some future parameters not currently used but the analytics team envisions using.

  You'll notice a lot of explicitly 'undefined' properties.
  This is weird in a normal context but Google Analytics likes explicit undefined values for certain parameters.
  Usually these being parameters regularly set on one variant of the event, but not the other.

  So you need to set it 'undefined' instead of omitting the value entirely. 
*/

var getQueryData = function getQueryData(queryString) {
  var _urlFacets$join;

  var queryParams = new URLSearchParams(queryString);
  var params = {};

  var _iterator = _createForOfIteratorHelper(queryParams.entries()),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var _step$value = _slicedToArray(_step.value, 2),
          _key = _step$value[0],
          value = _step$value[1];

      params[_key] = value;
    } // Multiple 'facet' instances can exist in a URL.
    // This gets all of them and puts them into their own array.

  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  var urlFacets = queryParams.getAll('facet');
  params.facetList = urlFacets === null || urlFacets === void 0 ? void 0 : (_urlFacets$join = urlFacets.join(' | ')) === null || _urlFacets$join === void 0 ? void 0 : _urlFacets$join.trim();
  return params;
}; // For easy analysis we want the filter names and categories only in English using their key names from the translations API.
// For readability we strip some common prefixes that just add clutter, and swap _ with spaces.


var removePrefixes = function removePrefixes(facetKey) {
  if (!facetKey) {
    return '';
  }

  return facetKey.toLowerCase().replaceAll('facet_web_', '').replaceAll('facet', '').replaceAll('_', ' ').replaceAll(':', ' : ').trim().toLowerCase();
};

export var buildFacetEventPayload = function buildFacetEventPayload(filterData) {
  filterData.filter_category = removePrefixes(filterData.filter_category);
  var parsedFilterName = removePrefixes(filterData === null || filterData === void 0 ? void 0 : filterData.filter_name);
  filterData.filter_name = parsedFilterName || undefined;

  if (filterData.element_text) {
    filterData.element_text = filterData.element_text.toLowerCase();
  } // Handle building and formatting of search terms, types, and counts from search page URL.


  var currentUrl = !isServer() ? parseRegionalUrl("".concat(window.location.pathname).concat(window.location.search)) : null;
  var queryData = currentUrl !== null && currentUrl !== void 0 && currentUrl.query ? getQueryData(currentUrl.query) : undefined;
  filterData.search_term = (filterData === null || filterData === void 0 ? void 0 : filterData.search_term) || (queryData === null || queryData === void 0 ? void 0 : queryData.term);
  filterData.search_type = (filterData === null || filterData === void 0 ? void 0 : filterData.search_type) || (queryData === null || queryData === void 0 ? void 0 : queryData.type);
  filterData.filter_existing = (filterData === null || filterData === void 0 ? void 0 : filterData.filter_existing) || (queryData === null || queryData === void 0 ? void 0 : queryData.facetList) || undefined;
  filterData.search_result_display = (filterData === null || filterData === void 0 ? void 0 : filterData.search_result_display) || "page ".concat(queryData === null || queryData === void 0 ? void 0 : queryData.page, " - sorted for ").concat(queryData === null || queryData === void 0 ? void 0 : queryData.sort, " - ").concat(queryData === null || queryData === void 0 ? void 0 : queryData.perpage, " results per page"); // TypeScript required return  params are not infered if set in function logic then given back on the same object.
  // So we make a new opject and set the changed required params for return using the spread operator.

  return _objectSpread(_objectSpread({}, filterData), {}, {
    event: (filterData === null || filterData === void 0 ? void 0 : filterData.event) || 'filter_interaction',
    filter_existing: filterData.filter_existing || undefined,
    core_event: filterData.core_event || 'no'
  });
}; // Create the expand/collapse analytics event log here as a function passed to FacetGroupCollapse
// This way we pass one function to call onClick with data instead of adding many more analytics only props to that component.
// Needs to be used in a few Facet components so it lives here instead of near a specific expand or collapse.

export var logExpandCollapseEvent = function logExpandCollapseEvent(state, toggledCat, toggleElText) {
  var accordionPayload = buildFacetEventPayload({
    action: "".concat(state, " filter"),
    component: 'left rail',
    element_type: 'accordion',
    element_text: toggleElText || undefined,
    filter_category: toggledCat
  });
  sendEvent({
    payload: accordionPayload
  });
};