import { defineMessages } from 'react-intl';
export var messages = defineMessages({
  NEED_ADDITIONAL_HELP: {
    id: 'NEED_ADDITIONAL_HELP',
    defaultMessage: 'Need Additional Help?'
  },
  ALDRICH_MARKET_SELECT_NSR: {
    id: 'ALDRICH_MARKET_SELECT_NSR',
    defaultMessage: 'your comprehensive solution for the identification and procurement of readily available screening compounds and building blocks.'
  },
  CUSTOMER_SUPPORT: {
    id: 'CUSTOMER_SUPPORT',
    defaultMessage: 'Customer Support.'
  },
  SORRY_NO_MATCHES_FOUND_FOR: {
    id: 'SORRY_NO_MATCHES_FOUND_FOR',
    defaultMessage: 'Sorry, we couldn’t find any matches for'
  },
  SORRY_NO_RETURN_RESPONSE_FOR: {
    id: 'SORRY_NO_RETURN_RESPONSE_FOR',
    defaultMessage: 'Sorry, we couldn’t return anything based on your search. Let’s help you find what you are looking for:'
  },
  SELECTION_CHIP_REMOVE_FILTER_1: {
    id: 'SELECTION_CHIP_REMOVE_FILTER_1',
    defaultMessage: 'Remove'
  },
  SELECTION_CHIP_REMOVE_TO_PERFORM_FACET_SEARCH: {
    id: 'SELECTION_CHIP_REMOVE_TO_PERFORM_FACET_SEARCH',
    defaultMessage: 'keyword to perform facet search'
  },
  KEYWORD: {
    id: 'KEYWORD',
    defaultMessage: 'Keyword'
  },
  SELECTION_CHIP_REMOVE_FILTER_2: {
    id: 'SELECTION_CHIP_REMOVE_FILTER_2',
    defaultMessage: 'filter'
  },
  FILTER_AND_SORT_HEADING: {
    id: 'FILTER_AND_SORT_HEADING',
    defaultMessage: 'Filter & Sort'
  },
  AVAILABLE_FOR_SALE: {
    id: 'AVAILABLE_FOR_SALE',
    defaultMessage: 'Available for Sale'
  },
  GLOBALLY: {
    id: 'GLOBALLY',
    defaultMessage: 'Globally'
  },
  SHOW_LESS: {
    id: 'SHOW_LESS',
    defaultMessage: 'Show Less'
  },
  SHOW_MORE: {
    id: 'SHOW_MORE',
    defaultMessage: 'Show More'
  },
  SORT_BY_RELEVANCE: {
    id: 'SORT_BY_RELEVANCE',
    defaultMessage: 'Sort by Relevance'
  },
  SORT_BY_NAME_ASCENDING: {
    id: 'SORT_BY_NAME_ASCENDING',
    defaultMessage: 'Sort by Name (Ascending)'
  },
  SORT_BY_NAME_DESCENDING: {
    id: 'SORT_BY_NAME_DESCENDING',
    defaultMessage: 'Sort by Name (Descending)'
  },
  SORT_BY_MOL_WT_ASC: {
    id: 'SORT_BY_MOL_WT_ASC',
    defaultMessage: 'Sort by Molecular Weight (Ascending)'
  },
  SORT_BY_MOL_WT_DESC: {
    id: 'SORT_BY_MOL_WT_DESC',
    defaultMessage: 'Sort by Molecular Weight (Descending)'
  },
  CLEAR_ALL: {
    id: 'CLEAR_ALL',
    defaultMessage: 'Clear All'
  },
  SORT_HEADING: {
    id: 'SORT_HEADING',
    defaultMessage: 'Sort'
  },
  SYNONYMS: {
    id: 'SYNONYMS',
    defaultMessage: 'Synonyms'
  },
  SUMMARY: {
    id: 'SUMMARY',
    defaultMessage: 'Summary'
  },
  HIDE_PRODUCTS: {
    id: 'HIDE_PRODUCTS',
    defaultMessage: 'Hide Products'
  },
  SHOW_PRODUCTS: {
    id: 'SHOW_PRODUCTS',
    defaultMessage: 'Show Products ({totalProducts})'
  },
  RELATED_PRODUCTS: {
    id: 'RELATED_PRODUCTS',
    defaultMessage: 'Related Products'
  },
  CAS_NO: {
    id: 'CAS_NO',
    defaultMessage: 'CAS No.'
  },
  MOLECULAR_WEIGHT: {
    id: 'MOLECULAR_WEIGHT',
    defaultMessage: 'Molecular Weight'
  },
  EC_NO: {
    id: 'EC_NO',
    defaultMessage: 'EC No.'
  },
  BEILSTEIN_NO: {
    id: 'BEILSTEIN_NO',
    defaultMessage: 'Beilstein No.'
  },
  SIMILARITY: {
    id: 'SIMILARITY',
    defaultMessage: 'Similarity'
  },
  DETAILS: {
    id: 'DETAILS',
    defaultMessage: 'Details'
  },
  COMPARE: {
    id: 'COMPARE',
    defaultMessage: 'Compare'
  },
  EXPAND: {
    id: 'EXPAND',
    defaultMessage: 'Expand'
  },
  HIDE: {
    id: 'HIDE',
    defaultMessage: 'Hide'
  },
  SUBSTANCE_VIEW_PRICING: {
    id: 'SUBSTANCE_VIEW_PRICING',
    defaultMessage: 'View Pricing'
  },
  CLONALITY: {
    id: 'CLONALITY',
    defaultMessage: 'Clonality'
  },
  APPLICATION: {
    id: 'APPLICATION',
    defaultMessage: 'Application'
  },
  CITATIONS: {
    id: 'CITATIONS',
    defaultMessage: 'Citations'
  },
  SDS: {
    id: 'SDS',
    defaultMessage: 'SDS'
  },
  PORE_SIZE: {
    id: 'PORE_SIZE',
    defaultMessage: 'Pore Size'
  },
  DIAMETER: {
    id: 'DIAMETER',
    defaultMessage: 'Diameter'
  },
  STERILITY: {
    id: 'STERILITY',
    defaultMessage: 'Sterility'
  },
  MATERIAL: {
    id: 'MATERIAL',
    defaultMessage: 'Material'
  },
  DESCRIPTION: {
    id: 'DESCRIPTION',
    defaultMessage: 'Description'
  },
  BRAND: {
    id: 'BRAND',
    defaultMessage: 'Brand'
  },
  PRICING: {
    id: 'PRICING',
    defaultMessage: 'Pricing'
  },
  PRODUCT_NO: {
    id: 'PRODUCT_NO',
    defaultMessage: 'Product No.'
  },
  LINEAR_FORMULA: {
    id: 'LINEAR_FORMULA',
    defaultMessage: 'Linear Formula'
  },
  EMPIRICAL_FORMULA_HILL_NOTATION: {
    id: 'EMPIRICAL_FORMULA_HILL_NOTATION',
    defaultMessage: 'Empirical Formula (Hill Notation)'
  },
  CONTACT_SELLER_SHIPPED_SOLD: {
    id: 'CONTACT_SELLER_SHIPPED_SOLD',
    defaultMessage: 'Shipped & Sold By'
  },
  CONTACT_SELLER_TRUSTED: {
    id: 'CONTACT_SELLER_TRUSTED',
    defaultMessage: 'A Trusted Marketplace Seller'
  },
  SEE_ALL_BUILDING_BLOCKS: {
    id: 'SEE_ALL_BUILDING_BLOCKS',
    defaultMessage: 'See All Building Blocks'
  },
  BUILDING_BLOCKS_EXPLORER: {
    id: 'BUILDING_BLOCKS_EXPLORER',
    defaultMessage: 'Building Blocks Explorer'
  },
  SEE_ALL_RELATED_GENES: {
    id: 'SEE_ALL_RELATED_GENES',
    defaultMessage: 'See All Related Genes'
  },
  NONE: {
    id: 'NONE',
    defaultMessage: 'None'
  },
  ALL_PHOTOS: {
    id: 'ALL_PHOTOS',
    defaultMessage: 'All Photos'
  },
  CAS_NUMBER: {
    id: 'CAS_NUMBER',
    defaultMessage: 'CAS Number'
  },
  EC_NUMBER: {
    id: 'EC_NUMBER',
    defaultMessage: 'EC Number'
  },
  BEILSTEIN_REGISTRY_NUMBER: {
    id: 'BEILSTEIN_REGISTRY_NUMBER',
    defaultMessage: 'Beilstein Registry Number'
  },
  EDIT_QUERY: {
    id: 'EDIT_QUERY',
    defaultMessage: 'Edit Query'
  },
  STRUCTURE_QUERY_IMAGE_ALT: {
    id: 'STRUCTURE_QUERY_IMAGE_ALT',
    defaultMessage: 'Structure query image. Only available when generated direct from editor'
  },
  NEW_SEARCH: {
    id: 'NEW_SEARCH',
    defaultMessage: 'New Search'
  },
  FACET_SEARCH_WITHIN: {
    id: 'FACET_SEARCH_WITHIN',
    defaultMessage: 'Search Within'
  },
  NO_FACETS_FOUND: {
    id: 'NO_FACETS_FOUND',
    defaultMessage: 'No facets found'
  },
  PRODUCTS: {
    id: 'PRODUCTS',
    defaultMessage: 'Products'
  },
  TECHNICAL_DOCUMENTS: {
    id: 'TECHNICAL_DOCUMENTS',
    defaultMessage: 'Technical Documents'
  },
  SITE_CONTENT: {
    id: 'SITE_CONTENT',
    defaultMessage: 'Site Content'
  },
  CHOOSE_UP_TO_FOUR_PRODUCTS: {
    id: 'CHOOSE_UP_TO_FOUR_PRODUCTS',
    defaultMessage: 'Choose up to 4 products to compare'
  },
  SHOWING: {
    id: 'SHOWING',
    defaultMessage: 'Showing'
  },
  RESULT: {
    id: 'RESULT',
    defaultMessage: 'result'
  },
  RESULTS: {
    id: 'RESULTS',
    defaultMessage: 'results'
  },
  SEARCH_RESULT_OF: {
    id: 'SEARCH_RESULT_OF',
    defaultMessage: 'of'
  },
  FOR: {
    id: 'FOR',
    defaultMessage: 'for'
  },
  WITHIN: {
    id: 'WITHIN',
    defaultMessage: 'within'
  },
  FACET_DOC_TYPE: {
    id: 'FACET_DOC_TYPE',
    defaultMessage: 'Document Type'
  },
  FACET_WEB_REFMATL_RMTYPE: {
    id: 'FACET_REFMATL_RMTYPE',
    defaultMessage: 'Reference Material Type'
  },
  FACET_WEB_REFMATL_APIFAM: {
    id: 'FACET_REFMATL_APIFAM',
    defaultMessage: 'API Family'
  },
  FACET_WEB_PROBE_PRESENTATION: {
    id: 'FACET_PROBE_PRESENTATION',
    defaultMessage: 'Probe Presentation'
  },
  FACET_WEB_PROBE_TYPE: {
    id: 'FACET_PROBE_TYPE',
    defaultMessage: 'Probe Sugar'
  },
  FACET_WEB_AGENCY_USP_SUITAB: {
    id: 'FACET_AGENCY_USP_SUITAB',
    defaultMessage: 'USP Suitability'
  },
  FACET_WEB_GREENER_CATEGORY: {
    id: 'FACET_GREENER_CATEGORY',
    defaultMessage: 'Greener Category'
  },
  FACET_WEB_ELEMENT: {
    id: 'FACET_WEB_ELEMENT',
    defaultMessage: 'Element'
  },
  FACET_WEB_ELEMENT_FAMILY: {
    id: 'FACET_WEB_ELEMENT_FAMILY',
    defaultMessage: 'Element Family'
  },
  FACET_WEB_POLYMERCHAIN_LEN: {
    id: 'FACET_WEB_POLYMERCHAIN_LEN',
    defaultMessage: 'Chain Length'
  },
  FACET_WEB_SAMPLE_IO_FEED_WATER_NATURE: {
    id: 'FACET_WEB_SAMPLE_IO_FEED_WATER_NATURE',
    defaultMessage: 'Feed Water Quality'
  },
  FACET_WEB_OPERATING_LIMIT_FLOWRATE: {
    id: 'FACET_WEB_OPERATING_LIMIT_FLOWRATE',
    defaultMessage: 'Production Flow Rate (L/hr)'
  },
  FACET_WEB_OPERATING_LIMIT_DISTFLOWRATE: {
    id: 'FACET_WEB_OPERATING_LIMIT_DISTFLOWRATE',
    defaultMessage: 'Distribution Flow Rate (L/min)'
  },
  FACET_WEB_OPERATING_LIMIT_MAXUSE: {
    id: 'FACET_WEB_OPERATING_LIMIT_MAXUSE',
    defaultMessage: 'Daily Water Usage (L/day)'
  },
  FACET_LANGUAGE: {
    id: 'FACET_LANGUAGE',
    defaultMessage: 'Language'
  },
  FACET_ORGANISM: {
    id: 'FACET_ORGANISM',
    defaultMessage: 'Organism'
  },
  FACET_RELATED_PRODUCT_CATEGORIES: {
    id: 'FACET_RELATED_PRODUCT_CATEGORIES',
    defaultMessage: 'Related Product Categories'
  },
  FACET_BOILING_POINT: {
    id: 'FACET_BOILING_POINT',
    defaultMessage: 'Boiling Point (°C)'
  },
  FACET_MELTING_POINT: {
    id: 'FACET_MELTING_POINT',
    defaultMessage: 'Melting Point (°C)'
  },
  FACET_WEB_CULTURE_MEDIA_COMP_GLUCOSE: {
    id: 'FACET_WEB_CULTURE_MEDIA_COMP_GLUCOSE',
    defaultMessage: 'Glucose (g/L)'
  },
  FACET_WEB_CULTURE_MEDIA_COMP_SODIUM_PYRUVATE: {
    id: 'FACET_WEB_CULTURE_MEDIA_COMP_SODIUM_PYRUVATE',
    defaultMessage: 'Sodium Pyruvate (g/L)'
  },
  FACET_WEB_CULTURE_MEDIA_COMP_HEPES: {
    id: 'FACET_WEB_CULTURE_MEDIA_COMP_HEPES',
    defaultMessage: 'HEPES (mM)'
  },
  FACET_WEB_COMP_LAYER_THICKNESS: {
    id: 'FACET_WEB_COMP_LAYER_THICKNESS',
    defaultMessage: 'Layer Thickness (µm)'
  },
  FACET_WEB_OPERATING_LIMIT_FREQUENCY_MHZ: {
    id: 'FACET_WEB_OPERATING_LIMIT_FREQUENCY_MHZ',
    defaultMessage: 'Frequency (MHz)'
  },
  FACET_WEB_NUCLEOSIDE_2_PROT: {
    id: 'FACET_WEB_NUCLEOSIDE_2_PROT',
    defaultMessage: "2' protecting group"
  },
  FACET_WEB_NUCLEOSIDE_5_PROT: {
    id: 'FACET_WEB_NUCLEOSIDE_5_PROT',
    defaultMessage: "5' protecting group"
  },
  FACET_WEB_NUCLEOSIDE_BASE: {
    id: 'FACET_WEB_NUCLEOSIDE_BASE',
    defaultMessage: 'Base'
  },
  FACET_WEB_NUCLEOSIDE_BASE_PROT: {
    id: 'FACET_WEB_NUCLEOSIDE_BASE_PROT',
    defaultMessage: 'Base protection'
  },
  FACET_WEB_MTX_ACT_GP: {
    id: 'FACET_WEB_MTX_ACT_GP',
    defaultMessage: 'Phase'
  },
  FACET_WEB_MARKET_FOCUS: {
    id: 'FACET_WEB_MARKET_FOCUS',
    defaultMessage: 'Application'
  },
  FACET_WEB_TITERTEST_APP: {
    id: 'FACET_WEB_TITERTEST_APP',
    defaultMessage: 'Technique'
  },
  FACET_WEB_SIZE_DESIGNATION_NEEDLE: {
    id: 'FACET_WEB_SIZE_DESIGNATION_NEEDLE',
    defaultMessage: 'Gauge'
  },
  FACET_WEB_FEATURE_CAP: {
    id: 'FACET_WEB_FEATURE_CAP',
    defaultMessage: 'Cap'
  },
  FACET_WEB_CELL_TRANSFORMATION_TYPE: {
    id: 'FACET_WEB_CELL_TRANSFORMATION_TYPE',
    defaultMessage: 'Transformation Type'
  },
  FACET_WEB_CELL_TRANSFORMATION_EFFICIENCY_CFU_UG: {
    id: 'FACET_WEB_CELL_TRANSFORMATION_EFFICIENCY_CFU_UG',
    defaultMessage: 'Transformation Efficiency (cfu/μg)'
  },
  FACET_WEB_CHEM_COMPOSITION: {
    id: 'FACET_WEB_CHEM_COMPOSITION',
    defaultMessage: 'Chemical Composition'
  },
  FACET_WEB_CHROMATOGRAPHY_MODE: {
    id: 'FACET_WEB_CHROMATOGRAPHY_MODE',
    defaultMessage: 'Separation Technique'
  },
  FACET_WEB_FEATURE_ELECTRONIC: {
    id: 'FACET_WEB_FEATURE_ELECTRONIC',
    defaultMessage: 'Electronic'
  },
  FACET_WEB_FILTER_CAPACITY_ML: {
    id: 'FACET_WEB_FILTER_CAPACITY_ML',
    defaultMessage: 'Filter Capacity (ml)'
  },
  FACET_WEB_FILTER_CAPACITY_UL: {
    id: 'FACET_WEB_FILTER_CAPACITY_UL',
    defaultMessage: 'Filter Capacity (µL)'
  },
  FACET_GENE_ALIAS: {
    id: 'FACET_GENE_ALIAS',
    defaultMessage: 'Gene Alias'
  },
  FACET_GEOGRAPHY: {
    id: 'FACET_GEOGRAPHY',
    defaultMessage: 'Geography'
  },
  FACET_WEB_MATERIAL_NEEDLE_SHAPE: {
    id: 'FACET_WEB_MATERIAL_NEEDLE_SHAPE',
    defaultMessage: 'Needle Shape'
  },
  FACET_WEB_PRODUCT_AREA: {
    id: 'FACET_WEB_PRODUCT_AREA',
    defaultMessage: 'Product Area'
  },
  FACET_PRODUCT_CATEGORY: {
    id: 'FACET_PRODUCT_CATEGORY',
    defaultMessage: 'Product Category'
  },
  FACET_PRODUCT_TYPE: {
    id: 'FACET_PRODUCT_TYPE',
    defaultMessage: 'Product Type'
  },
  FACET_RELATED_CATEGORY: {
    id: 'FACET_RELATED_CATEGORY',
    defaultMessage: 'Related Category'
  },
  FACET_WEB_FEATURE_SURFACE: {
    id: 'FACET_WEB_FEATURE_SURFACE',
    defaultMessage: 'Surface'
  },
  FACET_WEB_TUBE_SIZE_ML: {
    id: 'FACET_WEB_TUBE_SIZE_ML',
    defaultMessage: 'Tube Size (ml)'
  },
  FACET_WEB_MATERIAL_VIALBOTTLE: {
    id: 'FACET_WEB_MATERIAL_VIALBOTTLE',
    defaultMessage: 'Vial Bottle Material'
  },
  FACET_WEB_COMP_VOLUME_RANGE_UL: {
    id: 'FACET_WEB_COMP_VOLUME_RANGE_UL',
    defaultMessage: 'Volume Range (µL)'
  },
  FACET_WEB_COMP_WORKINGVOLUME_ML: {
    id: 'FACET_WEB_COMP_WORKINGVOLUME_ML',
    defaultMessage: 'Working Volume (ml)'
  },
  FACET_WEB_ACTIVITY_SPECTRUM: {
    id: 'FACET_WEB_ACTIVITY_SPECTRUM',
    defaultMessage: 'Activity Spectrum'
  },
  FACET_WEB_ADEQUATE_FOR_NO_REACTIONS: {
    id: 'FACET_WEB_ADEQUATE_FOR_NO_REACTIONS',
    defaultMessage: 'Number of Reactions'
  },
  FACET_WEB_AGENCY_METHOD: {
    id: 'FACET_WEB_AGENCY_METHOD',
    defaultMessage: 'Agency'
  },
  FACET_WEB_ANALYTE_SUITABILITY: {
    id: 'FACET_WEB_ANALYTE_SUITABILITY',
    defaultMessage: 'Analyte Suitability'
  },
  FACET_WEB_ANTIBODY_FORM: {
    id: 'FACET_WEB_ANTIBODY_FORM',
    defaultMessage: 'Antibody Form'
  },
  FACET_WEB_BATTERY_POWER: {
    id: 'FACET_WEB_BATTERY_POWER',
    defaultMessage: 'Battery Type'
  },
  FACET_WEB_FEATURE_BINDER: {
    id: 'FACET_WEB_FEATURE_BINDER',
    defaultMessage: 'Binder'
  },
  FACET_WEB_FEATURE_DNTPS: {
    id: 'FACET_WEB_FEATURE_DNTPS',
    defaultMessage: 'dNTPs'
  },
  FACET_WEB_FEATURE_HOTSTART: {
    id: 'FACET_WEB_FEATURE_HOTSTART',
    defaultMessage: 'Hot Start'
  },
  FACET_WEB_FEATURE_PCR_APPLICATION: {
    id: 'FACET_WEB_FEATURE_PCR_APPLICATION',
    defaultMessage: 'PCR Application'
  },
  FACET_WEB_POLYMERCHEM_POLYMERTYPE: {
    id: 'FACET_WEB_POLYMERCHEM_POLYMERTYPE',
    defaultMessage: 'Polymer Type'
  },
  FACET_WEB_POLYMERCHEM_MONOMERTYPE: {
    id: 'FACET_WEB_POLYMERCHEM_MONOMERTYPE',
    defaultMessage: 'Monomer Type'
  },
  FACET_WEB_POLYMERCHEM_POLYMERCOMPN: {
    id: 'FACET_WEB_POLYMERCHEM_POLYMERCOMPN',
    defaultMessage: 'Polymer Composition'
  },
  FACET_WEB_PEPTIDESYNTH_AMAC: {
    id: 'FACET_WEB_PEPTIDESYNTH_AMAC',
    defaultMessage: '	Amino Acid'
  },
  FACET_WEB_PEPTIDESYNTH_AMACTYPE: {
    id: 'FACET_WEB_PEPTIDESYNTH_AMACTYPE',
    defaultMessage: '	Amino Acid Type'
  },
  FACET_WEB_PEPTIDESYNTH_LINKER: {
    id: 'FACET_WEB_PEPTIDESYNTH_LINKER',
    defaultMessage: '	Linker'
  },
  FACET_WEB_POLYMERCHAIN_TYPE: {
    id: 'FACET_WEB_POLYMERCHAIN_TYPE',
    defaultMessage: 'Chain Type'
  },
  FACET_WEB_OPTICALISOMER: {
    id: 'FACET_WEB_OPTICALISOMER',
    defaultMessage: 'Optical Isomer'
  },
  FACET_WEB_PEPTIDESYNTH_CLEAVCOND: {
    id: 'FACET_WEB_PEPTIDESYNTH_CLEAVCOND',
    defaultMessage: 'Cleavage Condition'
  },
  FACET_WEB_SAMPLE_INPUT_PCR: {
    id: 'FACET_WEB_SAMPLE_INPUT_PCR',
    defaultMessage: 'Sample Type'
  },
  FACET_BIOLOGICAL_SOURCE: {
    id: 'FACET_BIOLOGICAL_SOURCE',
    defaultMessage: 'Biological Source'
  },
  FACET_WEB_MATERIAL_BOTTLE_DESC: {
    id: 'FACET_WEB_MATERIAL_BOTTLE_DESC',
    defaultMessage: 'Bottle material'
  },
  FACET_BRAND: {
    id: 'FACET_BRAND',
    defaultMessage: 'Brand'
  },
  FACET_WEB_COMP_CAPACITY_L: {
    id: 'FACET_WEB_COMP_CAPACITY_L',
    defaultMessage: 'Capacity (L)'
  },
  FACET_WEB_COMP_CAPACITY_ML: {
    id: 'FACET_WEB_COMP_CAPACITY_ML',
    defaultMessage: 'Capacity (mL)'
  },
  FACET_CLONALITY: {
    id: 'FACET_CLONALITY',
    defaultMessage: 'Clonality'
  },
  FACET_WEB_FEATURE_CLOSURE: {
    id: 'FACET_WEB_FEATURE_CLOSURE',
    defaultMessage: 'Closure Type'
  },
  FACET_COLOR: {
    id: 'FACET_COLOR',
    defaultMessage: 'Color'
  },
  FACET_COLLECTIONS: {
    id: 'FACET_COLLECTIONS',
    defaultMessage: 'Collections'
  },
  FACET_WEB_MATERIAL_COLOR: {
    id: 'FACET_WEB_MATERIAL_COLOR',
    defaultMessage: 'Color'
  },
  FACET_WEB_MATERIAL_COLUMN: {
    id: 'FACET_WEB_MATERIAL_COLUMN',
    defaultMessage: 'Column Material'
  },
  FACET_WEB_COLUMN_TYPE: {
    id: 'FACET_WEB_COLUMN_TYPE',
    defaultMessage: 'Column Type'
  },
  FACET_WEB_CONJUGATE: {
    id: 'FACET_WEB_CONJUGATE',
    defaultMessage: 'Conjugate'
  },
  FACET_WEB_FEATURE_CORE_TYPE: {
    id: 'FACET_WEB_FEATURE_CORE_TYPE',
    defaultMessage: 'Core Type'
  },
  FACET_WEB_NUCLEOSIDE_DEPROT: {
    id: 'FACET_WEB_NUCLEOSIDE_DEPROT',
    defaultMessage: 'Deprotection method'
  },
  FACET_WEB_COMP_DIAM_MM: {
    id: 'FACET_WEB_COMP_DIAM_MM',
    defaultMessage: 'Diameter (mm)'
  },
  FACET_WEB_CULTURE_MEDIA_COMP_EARLE: {
    id: 'FACET_WEB_CULTURE_MEDIA_COMP_EARLE',
    defaultMessage: 'Earles Salts'
  },
  FACET_WEB_MATERIAL_FIBER_DESC: {
    id: 'FACET_WEB_MATERIAL_FIBER_DESC',
    defaultMessage: 'Fiber material'
  },
  FACET_WEB_FITTINGS_THREADS: {
    id: 'FACET_WEB_FITTINGS_THREADS',
    defaultMessage: 'Fitting'
  },
  FACET_WEB_FITTINGS_THREADS_FITS_SIZE: {
    id: 'FACET_WEB_FITTINGS_THREADS_FITS_SIZE',
    defaultMessage: 'Fitting'
  },
  FACET_WEB_FEATURE_FLUORESCENT: {
    id: 'FACET_WEB_FEATURE_FLUORESCENT',
    defaultMessage: 'Fluorescence'
  },
  FACET_WEB_ANAL_STAND_FORM: {
    id: 'FACET_WEB_ANAL_STAND_FORM',
    defaultMessage: 'Format'
  },
  FACET_FWGHT: {
    id: 'FACET_FWGHT',
    defaultMessage: 'Formula Weight'
  },
  FACET_WEB_POLYMER_ARCH_FUNCTIONALITY: {
    id: 'FACET_WEB_POLYMER_ARCH_FUNCTIONALITY',
    defaultMessage: 'Functionality'
  },
  FACET_WEB_FEATURE_GENERATION: {
    id: 'FACET_WEB_FEATURE_GENERATION',
    defaultMessage: 'Generation'
  },
  FACET_WEB_GREENER_ALTERNATIVE_PRINCIPLES: {
    id: 'FACET_WEB_GREENER_ALTERNATIVE_PRINCIPLES',
    defaultMessage: 'Greener Alternative Principles'
  },
  FACET_WEB_CULTURE_MEDIA_COMP_HANKSALT: {
    id: 'FACET_WEB_CULTURE_MEDIA_COMP_HANKSALT',
    defaultMessage: 'Hanks Salts'
  },
  FACET_WEB_COMP_HEIGHT: {
    id: 'FACET_WEB_COMP_HEIGHT',
    defaultMessage: 'Height'
  },
  FACET_WEB_COMP_HEIGHT_IN: {
    id: 'FACET_WEB_COMP_HEIGHT_IN',
    defaultMessage: 'Height (in)'
  },
  FACET_WEB_COMP_HEIGHT_MM: {
    id: 'FACET_WEB_COMP_HEIGHT_MM',
    defaultMessage: 'Height (mm)'
  },
  FACET_WEB_HOST_SELECTION_HOST: {
    id: 'FACET_WEB_HOST_SELECTION_HOST',
    defaultMessage: 'Host'
  },
  FACET_WEB_MATERIAL_HOUSING: {
    id: 'FACET_WEB_MATERIAL_HOUSING',
    defaultMessage: 'Housing Material'
  },
  FACET_WEB_MATERIAL_HUB_COLOR: {
    id: 'FACET_WEB_MATERIAL_HUB_COLOR',
    defaultMessage: 'Hub color'
  },
  FACET_WEB_COMP_ID_MM: {
    id: 'FACET_WEB_COMP_ID_MM',
    defaultMessage: 'ID (mm)'
  },
  FACET_WEB_ISOTOPIC_PURITY_ISOTOPE: {
    id: 'FACET_WEB_ISOTOPIC_PURITY_ISOTOPE',
    defaultMessage: 'Isotope'
  },
  FACET_ISOTYPE: {
    id: 'FACET_ISOTYPE',
    defaultMessage: 'Isotype'
  },
  FACET_WEB_CULTURE_MEDIA_COMP_LGLUTAMINE: {
    id: 'FACET_WEB_CULTURE_MEDIA_COMP_LGLUTAMINE',
    defaultMessage: 'L-Glutamine'
  },
  FACET_WEB_COMP_LENGTH_CM: {
    id: 'FACET_WEB_COMP_LENGTH_CM',
    defaultMessage: 'Length (cm)'
  },
  FACET_WEB_COMP_LENGTH_FT: {
    id: 'FACET_WEB_COMP_LENGTH_FT',
    defaultMessage: 'Length (ft)'
  },
  FACET_WEB_COMP_LENGTH_IN: {
    id: 'FACET_WEB_COMP_LENGTH_IN',
    defaultMessage: 'Length (in)'
  },
  FACET_WEB_COMP_LENGTH_M: {
    id: 'FACET_WEB_COMP_LENGTH_M',
    defaultMessage: 'Length (m)'
  },
  FACET_WEB_COMP_LENGTH_MM: {
    id: 'FACET_WEB_COMP_LENGTH_MM',
    defaultMessage: 'Length (mm)'
  },
  FACET_MANUFACTURER_NAME: {
    id: 'FACET_MANUFACTURER_NAME',
    defaultMessage: 'Manufacturer'
  },
  FACET_MASS_SHIFT: {
    id: 'FACET_MASS_SHIFT',
    defaultMessage: 'Mass Shift'
  },
  FACET_WEB_MATERIAL_MATERIALDESC: {
    id: 'FACET_WEB_MATERIAL_MATERIALDESC',
    defaultMessage: 'Material'
  },
  FACET_WEB_MATERIAL_MATRIX: {
    id: 'FACET_WEB_MATERIAL_MATRIX',
    defaultMessage: 'Matrix Material'
  },
  FACET_WEB_MATERIAL_SUPPORT: {
    id: 'FACET_WEB_MATERIAL_SUPPORT',
    defaultMessage: 'Support Material'
  },
  FACET_WEB_MATERIAL_MEMBRANE: {
    id: 'FACET_WEB_MATERIAL_MEMBRANE',
    defaultMessage: 'Membrane Material'
  },
  FACET_WEB_MEDIA_SUITABILITY: {
    id: 'FACET_WEB_MEDIA_SUITABILITY',
    defaultMessage: 'Microorganism Suitability'
  },
  FACET_WEB_MODE_OF_ACTION: {
    id: 'FACET_WEB_MODE_OF_ACTION',
    defaultMessage: 'Mode of Action'
  },
  FACET_WEB_COMP_OD_IN: {
    id: 'FACET_WEB_COMP_OD_IN',
    defaultMessage: 'OD (in)'
  },
  FACET_WEB_COMP_OD_MM: {
    id: 'FACET_WEB_COMP_OD_MM',
    defaultMessage: 'OD (mm)'
  },
  FACET_WEB_ORIGINAL_DEVELOPER: {
    id: 'FACET_WEB_ORIGINAL_DEVELOPER',
    defaultMessage: 'Original Developer'
  },
  FACET_WEB_PEPTIDE_CLEAVAGE: {
    id: 'FACET_WEB_PEPTIDE_CLEAVAGE',
    defaultMessage: 'Peptide Cleavage'
  },
  FACET_PH_VAL: {
    id: 'FACET_PH_VAL',
    defaultMessage: 'pH Value'
  },
  FACET_WEB_CULTURE_MEDIA_COMP_PHENOL: {
    id: 'FACET_WEB_CULTURE_MEDIA_COMP_PHENOL',
    defaultMessage: 'Phenol Red'
  },
  FACET_PHYSICAL_FORM: {
    id: 'FACET_PHYSICAL_FORM',
    defaultMessage: 'Physical Form'
  },
  FACET_PK_VAL: {
    id: 'FACET_PK_VAL',
    defaultMessage: 'pK Value'
  },
  FACET_WEB_MATERIAL_PLATE: {
    id: 'FACET_WEB_MATERIAL_PLATE',
    defaultMessage: 'Plate Material'
  },
  FACET_WEB_PORE_SIZE_A: {
    id: 'FACET_WEB_PORE_SIZE_A',
    defaultMessage: 'Pore Size (Å)'
  },
  FACET_WEB_PORE_SIZE_DIM1_UM: {
    id: 'FACET_WEB_PORE_SIZE_DIM1_UM',
    defaultMessage: 'Pore size (µm)'
  },
  FACET_WEB_PARTICLE_SIZE_UM: {
    id: 'FACET_WEB_PARTICLE_SIZE_UM',
    defaultMessage: 'Particle Size (µm)'
  },
  FACET_WEB_PROMOTER: {
    id: 'FACET_WEB_PROMOTER',
    defaultMessage: 'Promoter'
  },
  FACET_PURITY: {
    id: 'FACET_PURITY',
    defaultMessage: 'Purity'
  },
  FACET_WEB_REACT_SUITABILITY_REACTION_TYPE: {
    id: 'FACET_WEB_REACT_SUITABILITY_REACTION_TYPE',
    defaultMessage: 'Reaction Type'
  },
  FACET_WEB_REACT_SUITABILITY_REACTIVITY: {
    id: 'FACET_WEB_REACT_SUITABILITY_REACTIVITY',
    defaultMessage: 'Reactivity'
  },
  FACET_WEB_REACT_SUITABILITY_REAGENT_TYPE: {
    id: 'FACET_WEB_REACT_SUITABILITY_REAGENT_TYPE',
    defaultMessage: 'Reagent Type'
  },
  FACET_RECOMBINANT_HOST: {
    id: 'FACET_RECOMBINANT_HOST',
    defaultMessage: 'Recombinant Host'
  },
  FACET_WEB_RELEVANT_DISEASE: {
    id: 'FACET_WEB_RELEVANT_DISEASE',
    defaultMessage: 'Relevant Disease'
  },
  FACET_WEB_REPORTER_GENE: {
    id: 'FACET_WEB_REPORTER_GENE',
    defaultMessage: 'Reporter Gene'
  },
  FACET_WEB_HOST_SELECTION_SELECTION: {
    id: 'FACET_WEB_HOST_SELECTION_SELECTION',
    defaultMessage: 'Selection Method'
  },
  FACET_WEB_POLYMER_ARCH_SHAPE: {
    id: 'FACET_WEB_POLYMER_ARCH_SHAPE',
    defaultMessage: 'Shape'
  },
  FACET_WEB_SIZE_DESIGNATION: {
    id: 'FACET_WEB_SIZE_DESIGNATION',
    defaultMessage: 'Size'
  },
  FACET_WEB_CULTURE_MEDIA_COMP_NAHCO3: {
    id: 'FACET_WEB_CULTURE_MEDIA_COMP_NAHCO3',
    defaultMessage: 'Sodium Bicarbonate'
  },
  FACET_WEB_SPECIAL_GRADE: {
    id: 'FACET_WEB_SPECIAL_GRADE',
    defaultMessage: 'Special Grade'
  },
  FACET_WEB_SPECIES_REACTIVITY: {
    id: 'FACET_WEB_SPECIES_REACTIVITY',
    defaultMessage: 'Species Reactivity'
  },
  FACET_WEB_STERILIZATION: {
    id: 'FACET_WEB_STERILIZATION',
    defaultMessage: 'Sterility'
  },
  FACET_STERILIZABILITY_COMPAT: {
    id: 'FACET_STERILIZABILITY_COMPAT',
    defaultMessage: 'Sterilization Compatibility'
  },
  FACET_WEB_TAXON: {
    id: 'FACET_WEB_TAXON',
    defaultMessage: 'Taxon'
  },
  'FACET_WEB_MEASURRANGE_WEIGHCAPACITY_LINEAR-G': {
    id: 'FACET_WEB_MEASURRANGE_WEIGHCAPACITY_LINEAR-G',
    defaultMessage: 'Linearity (g)'
  },
  FACET_WEB_BIOLSOURCE_ORGANISMPART: {
    id: 'FACET_WEB_BIOLSOURCE_ORGANISMPART',
    defaultMessage: 'Tissue'
  },
  FACET_WEB_MATERIAL_VIALBOTTLE_COLOR: {
    id: 'FACET_WEB_MATERIAL_VIALBOTTLE_COLOR',
    defaultMessage: 'Vial Bottle Color'
  },
  FACET_WEB_COMP_VOLUME_L: {
    id: 'FACET_WEB_COMP_VOLUME_L',
    defaultMessage: 'Volume (l)'
  },
  FACET_WEB_COMP_VOLUME_ML: {
    id: 'FACET_WEB_COMP_VOLUME_ML',
    defaultMessage: 'Volume (ml)'
  },
  FACET_WEB_COMP_VOLUME_UL: {
    id: 'FACET_WEB_COMP_VOLUME_UL',
    defaultMessage: 'Volume (µL)'
  },
  FACET_WEB_COMP_WELLS: {
    id: 'FACET_WEB_COMP_WELLS',
    defaultMessage: 'Wells'
  },
  FACET_WEB_COMP_WIDTH_IN: {
    id: 'FACET_WEB_COMP_WIDTH_IN',
    defaultMessage: 'Width (in)'
  },
  FACET_WEB_COMP_WIDTH_MM: {
    id: 'FACET_WEB_COMP_WIDTH_MM',
    defaultMessage: 'Width (mm)'
  },
  FACET_PRODUCT_LINE: {
    id: 'FACET_PRODUCT_LINE',
    defaultMessage: 'Product Line'
  },
  FACET_WEB_SPECIES_REACT_NO: {
    id: 'FACET_WEB_SPECIES_REACT_NO',
    defaultMessage: 'Non-reactive Species'
  },
  FACET_WEB_PACKID: {
    id: 'FACET_WEB_PACKID',
    defaultMessage: 'Package Size'
  },
  FACET_FEATURE: {
    id: 'FACET_FEATURE',
    defaultMessage: 'Feature'
  },
  FACET_SERVICE: {
    id: 'FACET_SERVICE',
    defaultMessage: 'Service'
  },
  FACET_SHIPPING: {
    id: 'FACET_SHIPPING',
    defaultMessage: 'Shipping'
  },
  FACET_CONTENT_TYPE: {
    id: 'FACET_CONTENT_TYPE',
    defaultMessage: 'Content Type'
  },
  FACET_APPLICATIONS: {
    id: 'FACET_APPLICATIONS',
    defaultMessage: 'Applications'
  },
  FACET_TOPIC_AREA: {
    id: 'FACET_TOPIC_AREA',
    defaultMessage: 'Topic Area'
  },
  FACET_WEB_ANTIBODY_RESEARCH_AREA: {
    id: 'FACET_WEB_ANTIBODY_RESEARCH_AREA',
    defaultMessage: 'Research Area'
  },
  FACET_WEB_MEASURRANGE_WEIGHCAPACITY_RESOL_G: {
    id: 'FACET_WEB_MEASURRANGE_WEIGHCAPACITY_RESOL_G',
    defaultMessage: 'Resolution (g)'
  },
  FACET_WEB_MEASURRANGE_WEIGHCAPACITY_CAP_G: {
    id: 'FACET_WEB_MEASURRANGE_WEIGHCAPACITY_CAP_G',
    defaultMessage: 'Weighing Capacity (g)'
  },
  FACET_CLEAR_ALL_BUTTON: {
    id: 'FACET_CLEAR_ALL_BUTTON',
    defaultMessage: 'Clear All'
  },
  FACET_GENE_ID: {
    id: 'FACET_GENE_ID',
    defaultMessage: 'Gene ID'
  },
  FACET_WEB_ANALYTESUITAB: {
    id: 'FACET_WEB_ANALYTESUITAB',
    defaultMessage: 'Analyte Suitability'
  },
  FACET_WEB_BINDING_TYPE: {
    id: 'FACET_WEB_BINDING_TYPE',
    defaultMessage: 'Binding Type'
  },
  FACET_WEB_COMPATIBILITY_FORUSEWITH_MFR: {
    id: 'FACET_WEB_COMPATIBILITY_FORUSEWITH_MFR',
    defaultMessage: 'Compatibility'
  },
  FACET_WEB_CULTURE_MEDIA_COMP_STABLE: {
    id: 'FACET_WEB_CULTURE_MEDIA_COMP_STABLE',
    defaultMessage: 'Stable Glutamine'
  },
  FACET_WEB_DETECTION_METHOD: {
    id: 'FACET_WEB_DETECTION_METHOD',
    defaultMessage: 'Detection Method'
  },
  FACET_WEB_DETECTION_METHOD_AGENT: {
    id: 'FACET_WEB_DETECTION_METHOD_AGENT',
    defaultMessage: 'Detection Method'
  },
  FACET_WEB_FEATURE_GLASSWARECLASS: {
    id: 'FACET_WEB_FEATURE_GLASSWARECLASS',
    defaultMessage: 'Glassware Class'
  },
  FACET_WEB_FEATURE_HYDROPHILIC_HYDROPHOBIC: {
    id: 'FACET_WEB_FEATURE_HYDROPHILIC_HYDROPHOBIC',
    defaultMessage: 'Hydrophobicity'
  },
  FACET_WEB_FUNCTGP: {
    id: 'FACET_WEB_FUNCTGP',
    defaultMessage: 'Functional Group'
  },
  FACET_WEB_GEOGRAPHIC_ORIGIN: {
    id: 'FACET_WEB_GEOGRAPHIC_ORIGIN',
    defaultMessage: 'Geographic Origin'
  },
  FACET_WEB_MARKUSH_CLASS: {
    id: 'FACET_WEB_MARKUSH_CLASS',
    defaultMessage: 'Markush Class'
  },
  FACET_WEB_MARKUSH_GROUP: {
    id: 'FACET_WEB_MARKUSH_GROUP',
    defaultMessage: 'Markush Group'
  },
  FACET_WEB_MATRIXACTGP_SURFACE: {
    id: 'FACET_WEB_MATRIXACTGP_SURFACE',
    defaultMessage: 'Surface Treatment'
  },
  FACET_WEB_MISC_SPEC_GENDER: {
    id: 'FACET_WEB_MISC_SPEC_GENDER',
    defaultMessage: 'Gender'
  },
  FACET_WEB_ORGANOLEPTIC_CLASS: {
    id: 'FACET_WEB_ORGANOLEPTIC_CLASS',
    defaultMessage: 'Organoleptic Class'
  },
  FACET_WEB_PORE_SIZE_MWCO_KDA: {
    id: 'FACET_WEB_PORE_SIZE_MWCO_KDA',
    defaultMessage: 'MWCO (kDa)'
  },
  FACET_WEB_REACTION_SUITABILITY_CATALYTIC_CORE: {
    id: 'FACET_WEB_REACTION_SUITABILITY_CATALYTIC_CORE',
    defaultMessage: 'Core'
  },
  FACET_WEB_REPROG_METHOD: {
    id: 'FACET_WEB_REPROG_METHOD',
    defaultMessage: 'Reprogramming Method'
  },
  FACET_WEB_SAMPLE_IO_WATER_QUALITY: {
    id: 'FACET_WEB_SAMPLE_IO_WATER_QUALITY',
    defaultMessage: 'Output Water Quality'
  },
  FACET_WEB_STERILIZATION_METHOD: {
    id: 'FACET_WEB_STERILIZATION_METHOD',
    defaultMessage: 'Sterilization Method'
  },
  FACET_WEB_VESSELSTYLE: {
    id: 'FACET_WEB_VESSELSTYLE',
    defaultMessage: 'Style'
  },
  CHROMATOGRAMS: {
    id: 'CHROMATOGRAMS',
    defaultMessage: 'Chromatograms'
  },
  CHROMATOGRAM_SEARCH_VALIDATION_MSG: {
    id: 'CHROMATOGRAM_SEARCH_VALIDATION_MSG',
    defaultMessage: 'Please enter a term or select a technique.'
  },
  CHROMATOGRAM_SEARCH_PROMPT: {
    id: 'CHROMATOGRAM_SEARCH_PROMPT',
    defaultMessage: 'Find a Chromatogram Using the Fields Below'
  },
  SEARCH_BY_KEYWORD: {
    id: 'SEARCH_BY_KEYWORD',
    defaultMessage: 'Search by Keyword'
  },
  TECHNIQUE: {
    id: 'TECHNIQUE',
    defaultMessage: 'Technique'
  },
  SEARCH: {
    id: 'SEARCH',
    defaultMessage: 'Search'
  },
  RESET: {
    id: 'RESET',
    defaultMessage: 'Reset'
  },
  FACET_WEB_RENEWABLE_CARBON: {
    id: 'FACET_WEB_RENEWABLE_CARBON',
    defaultMessage: 'Renewable Carbon Content'
  },
  FACET_WEB_EQP: {
    id: 'FACET_WEB_EQP',
    defaultMessage: 'Quality Segment'
  },
  FACET_WEB_MISC_SPEC_DF_UOM: {
    id: 'FACET_WEB_MISC_SPEC_DF_UOM',
    defaultMessage: 'd<SUB>f</SUB> (µm)'
  },
  FACET_WEB_COMP_SURFACE_AREA_CM2: {
    id: 'FACET_WEB_COMP_SURFACE_AREA_CM2',
    defaultMessage: 'Surface Area cm²'
  },
  FACET_WEB_GREENER_CATEGORY_SUSTAINABILITY: {
    id: 'FACET_WEB_GREENER_CATEGORY_SUSTAINABILITY',
    defaultMessage: 'Sustainability'
  },
  FACET_WEB_POSTTRANSMOD: {
    id: 'FACET_WEB_POSTTRANSMOD',
    defaultMessage: 'Post Translational Modification'
  }
});
export default messages;